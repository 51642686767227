
import useVuelidate from "@vuelidate/core";
import { required } from "@/utils/i18n-validators";
import { Options, Vue } from "vue-class-component";
import FormInput from "@/shared/components/FormInput.vue";
import FormCheckbox from "@/shared/components/FormCheckbox.vue";
import FormSelect from "@/shared/components/FormSelect.vue";
import FormRadioGroup from "@/shared/components/FormRadioGroup.vue";
import { helpers } from "@vuelidate/validators";
import { maxDate, validateIban } from "@/utils/validators";
import { mapGetters } from "vuex";

@Options({
  data() {
    return {
      v$: useVuelidate(),
      formSubmitted: false,
      formDefault: {
        street: "",
        zip: "",
        city: "",
        country_id: "",
        tax_residence_id: "",
        bank_account_owner_name: "",
        iban: "",
      },
      fileName5: this.$t("select_file"),
      file5: {},
      showError: false
    };
  },
  mounted() {
    this.loadProfile();
  },
  computed: {
    titleKey() {
      if (typeof localStorage !== "undefined") {
        return localStorage.getItem("wem_locale") === "en" ? "name_en" : "name";
      }
      return "name"; // Fallback value when localStorage is not available
    },
    countries() {
      return this.$store.getters["onboarding/allCountries"];
    },
    form() {
      if (!this.$store.state.onboarding.profile) return this.formDefault;
      return this.$store.state.onboarding.profile;
    },
    ...mapGetters({
      riskProfile: "onboarding/myProfile",
    }),
  },
  components: {
    FormInput,
    FormCheckbox,
    FormSelect,
    FormRadioGroup,
  },
  validations() {
    return {
      form: {
        street: { required },
        zip: { required },
        city: { required },
        country_id: { required },
        tax_residence_id: { required },
        bank_account_owner_name: { required },
        iban: {
          required,
          validateIban: helpers.withMessage(
            this.$t("validations.IBAN"),
            validateIban
          ),
        },
      },
    };
  },
  methods: {
    updateModel(value: string, model: string) {
      this.v$.form[model].$touch();
      this.form[model] = value;
    },
    open5() {
      this.resetImage5();
      this.$refs.document5.click();
    },
    resetImage5() {
      this.fileName5 = this.$t("select_file");
    },
    updateFiles5(e: any) {
      const selectedImage = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      reader.onload = () => {
        this.fileName5 = selectedImage.name;
        this.file5 = this.$refs.document5.files[0];
      };
    },
    submitForm() {
      this.v$.$validate();
      this.formSubmitted = true;
      const form = this.form;
      var params = new FormData();
      params.append("street", form.street);
      params.append("zip", form.zip);
      params.append("city", form.city);
      params.append("country_id", form.country_id);
      params.append("tax_residence_id", form.tax_residence_id);
      params.append("bank_account_owner_name", form.bank_account_owner_name);
      params.append("iban", form.iban);
      params.append("scan_bank", this.file5);

if (this.fileName5 !==  this.$t("select_file")) {
  this.showError = false
  // };
  if (!this.v$.$invalid) {
    this.$store.dispatch(
      "onboarding/sendAddressAndBankAccountInfo",
      params,

    );
  }
} else {
  this.showError = true
}

      // const data = {
      //   street: form.street,
      //   zip: form.zip,
      //   city: form.city,
      //   country_id: parseInt(form.country_id),
      //   tax_residence_id: parseInt(form.tax_residence_id),
      //   bank_account_owner_name: form.bank_account_owner_name,
      //   iban: form.iban,

    },
    async loadProfile() {
      console.log("loadProfile")
      await this.$store.dispatch("onboarding/getProfile");
    },
  },
})
export default class AddressAndBankAccountForm extends Vue {}
